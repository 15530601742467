@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

body {
  font-family: "Open Sans", sans-serif;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}

html {
  overflow-y: hidden;
}

.customNavbar {
  background-color: white;
  padding: 0px;
  margin: 0px;
}

.customNav {
  text-align: center;
  text-decoration: none;
  color: #d62828;
}

.mainContainer {
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.navbar-hamburger {
  border-color: red;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-toggler {
  border-color: rgba(255, 255, 255, 0.1);
}

.active {
  font: bold;
  text-align: center;
  border-bottom: 3px solid #4395f5;
  transition: all 0.1s ease-in-out;
}
.toast_body {
  z-index: 30;
  padding: 10px 20px;
  background: #15b769;
  color: #fff;
  border-radius: 50px;
  box-shadow: 2px 2px 5px 2px #24232334;
  position: absolute;
  top: 80px;
  right: 20px;
  font-weight: 500;
}
.offerBanner {
  position: sticky;
  top: 0;
  z-index: 20;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(50deg, #0f7d87, #00a3ff);
}
.typing_text {
  color: #4395f5;
  font-weight: 600;
  font-size: 40px;
  height: 100px;
}
.reviewContainer {
  background-color: #2ea1e82b;
  margin: 3rem 0;
  padding: 1rem;
}
.wrapper-instructor {
  background-color: #2ea1e82b;
  padding: 20px 0;
}
.instructors {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: fit-content;
  margin: 10px auto;
}
.contact-form .form-group {
  margin-bottom: 15px;
}

.contact-form .form-control {
  width: 100%; /* Ensures full width on small screens */
}

.modal-header-custom .close {
  color: #000; /* Change color to improve contrast */
  opacity: 1; /* Make sure it's fully opaque */
}

/* If needed, adjust the z-index */
.modal-header-custom .close {
  z-index: 1051;
}

.hero_right {
  background-color: none;
}
.video_container {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  margin: 20px;
  border: 1px solid #e0e0e084;
  margin: auto;
  box-shadow: 0px 1px 10px 5px #e0e0e0ca;
}
.video_container iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.video_container .lines1 {
  position: absolute;
  top: -20px;
  right: -30px;
  width: 60px;
  z-index: -1;
}
.modal-dialog {
  max-width: 80vw;
}

.course_cards {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: auto;
}
.course_body {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
  overflow: hidden;
}
.course_cover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-image: linear-gradient(-40deg, #173e5547, #0a0a0a5c, #03296361);
  color: #fff;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
}
.course_card {
  margin: 20px 10px;
  background-color: #fff;
  box-shadow: 0px 10px 15px 1px #95959533;
  color: #000;
  width: 300px;
  height: 42 0px;
  overflow: hidden;
}
/* ____________________________ */
.course_card:hover {
  box-shadow: 0px 10px 15px 1px #4a494933;
}
.course_card .course_label {
  z-index: 15;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 40px;
  padding: 5px 15px;
  font-size: 12px;
  font-weight: 500;
  margin-left: auto;
  background-color: #00c09d;
  color: white;
  width: fit-content;
}
.course_card .course_heading {
  margin-bottom: 5px;
  font-size: 24px;
  font-weight: 500;
}
.course_card .course_price {
  color: #4395f5;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.course_card .course_price span {
  color: #000;
  font-size: 16px;
  text-decoration: line-through;
  margin-left: 5px;
  font-weight: 400;
}
.course_card .course_price .discount {
  font-size: 20px;
  margin-left: 5px;
  font-weight: 500;
  color: #15b769;
}
.course_card label {
  color: gray;
}
.course_card ul {
  margin: 10px 0 20px 0px;
  padding: 0;
}
.course_card ul li {
  list-style: none;
  color: rgb(68, 68, 68);
}
.course_card .btn_div {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: space-between;
  z-index: 10;
  gap: 10px;
}
.course_card .btn_div .course_btn {
  border-radius: 50px;
  text-align: center;
  width: 50%;
  text-decoration: none;
  background-color: #fff;
  color: gray;
  border: 2px solid gray;
  font-weight: 400;
  padding: 10px 20px;
}
.course_card .btn_div .course_btn:hover {
  cursor: pointer;
  color: #4395f5;
  border-color: #4395f5;
}
.course_card .btn_div .course_card .demo_btn {
  border-radius: 50px;
  text-align: center;
  padding: 10px 30px;
  background-color: #4395f5;
  color: #fff;
  width: 50%;
  text-decoration: none;
  border: 2px solid #4395f5;
}
.course_card .btn_div .course_card .demo_btn:hover {
  border: 2px solid #0f6dd9;
  cursor: pointer;
  background-color: #0f6dd9;
  color: #fff;
}
.course_card .rectangle1 {
  z-index: 0;
  width: 70px;
  height: 30px;
  transform: rotate(-45deg);
  border-radius: 50%;
  position: absolute;
  left: -30px;
  bottom: -20px;
  background-color: #ff63f54e;
}
.course_card .rectangle2 {
  z-index: 0;
  width: 70px;
  height: 30px;
  transform: rotate(-45deg);
  border-radius: 100%;
  position: absolute;
  left: -10px;
  bottom: -30px;
  background-color: #2ad9e250;
}
.course_card .circle1 {
  z-index: 0;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  position: absolute;
  right: -70px;
  top: -100px;
  background-color: #0026ff28;
}
.course_card .circle2 {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: absolute;
  right: 50px;
  top: 120px;
  background-color: rgba(47, 219, 173, 0.299);
}
.course_card .circle3 {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
  right: 10px;
  top: 150px;
  background-color: #ffbd2339;
}
/* ____________________________ */
.opportunity_body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.callback_container {
  width: 100vw;
  background-color: #2ea1e82b;
  border-radius: 20px;
  width: 80%;
  padding: 20px;
  margin: 20px auto;
}
.whatsapp_icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.footer {
  background-image: linear-gradient(50deg, #0b5d64, #006ba9);
  color: #fff;
  padding: 30px 20px;
}
.footer .footer_links {
  margin: 10px;
  background-color: #00000018;
  border-radius: 50px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

@media (min-width: 768px) {
  .contact-form .d-flex {
    flex-direction: row; /* Side-by-side layout on larger screens */
  }
  .typing_text {
    height: 50px;
  }
  .course_cards {
    flex-direction: row;
  }
  .course_cover {
    font-size: 50px;
  }
  .course_card {
    width: 500px;
  }
  .opportunity_body {
    flex-direction: row;
  }
  .whatsapp_icon {
    bottom: 30px;
    right: 30px;
  }
}

/* // large devices. */
@media only screen and (min-width: 1000px) {
  .customBrand {
    display: block;
  }

  .headerimg {
    height: 100vh;
    display: block;
  }
}

/* // medium device */
@media only screen and (min-width: 801px) and (max-width: 1000px) {
  .header {
    height: 40vh;
  }
  .headerimg {
    display: none;
  }
}

/* // small device */
@media only screen and (max-width: 800px) {
  .reviews-section {
    margin: 3rem 0;
    background-color: whitesmoke;
  }

  .headerimg {
    display: none;
  }
}
