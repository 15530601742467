.instructor_card {
  margin: 25px 20px;
  background-color: #fff;
  box-shadow: 0px 5px 10px 2px #7070703d;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  min-height: 200px;
}

.instructor_card .instructor_img {
  background-color: white;
  position: absolute;
  top: -30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 110px;
  border-radius: 100%;
  padding: 10px;
}
.instructor_card .instructor_img .instructor_picture {
  width: 100px;
  height: 100px;
  border-radius: 100%;
}
.instructor_card .instructor_img .instructor_company {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 2px 2px 5px 2px #3e3e3e3d;
  position: absolute;
  bottom: 5px;
  right: 2%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.instructor_card .instructor_img .instructor_company img {
  width: 20px;
  height: 20px;
}
.instructor_card .instrucor_name {
  margin-top: 3rem;
  font-size: 24px;
  font-weight: 500;
}
.instructor_card .expertise {
  font-size: 14px;
  color: #626262;
  text-align: center;
}
.instructor_card .expertise .inverted_quote {
  transform: rotate(180deg);
}
.instructor_card .linkedin_link {
  background-color: #4395f5;
  border-radius: 50px;
  padding: 10px 15px;
  width: 90%;
  color: white;
  text-align: center;
  text-decoration: none;
  margin-bottom: 10px;
}
.instructor_card .linkedin_link:hover {
  background-color: #267bdc;
}
@media only screen and(max-width:800px) {
  .instructor_card {
    margin: 20px 20px 40px 20px;
  }
}
