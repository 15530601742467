.customSlider {
    position: relative;
    display: block;
    width: auto;
    max-width: none;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0;
    padding-left: 0;
    justify-content: flex-start;
    align-items: stretch;
    background-color: transparent;
    min-height: auto;
    margin-bottom: 0;
    width: 100%;
    overflow: hidden;
}
.customSlider .sliderRef {
    display: flex; 
    padding-left: 4rem; 
    padding-right: 4rem;
}
.customSlider .slickPrevBtn {
    position: absolute;
    top: 40%;
    z-index: 1;
    background: transparent;
    border: none;
    font-size: 24px;
    font-weight: 800;
    color: #4a4d4d;

    img {
        height: 4rem;
        width: 4rem;
    }
}

.customSlider .slickNextBtn {
    position: absolute;
    top: 40%;
    right: 0;
    z-index: 1;
    background: transparent;
    border: none;
    font-size: 24px;
    font-weight: 800;
    color: #4a4d4d;
    img {
        height: 4rem;
        width: 4rem;
    }
}
.customSlider .slick-slider {
    position: relative !important;
    display: block !important;
    
    .slick-prev {
        display: none;
        visibility: hidden;
    }
    .slick-next {
        display: none;   
        visibility: hidden;       
    } 

    .slick-list {
        overflow: hidden !important;
        margin: 0 !important;
    }

    .slick-track {
        display: flex !important;
    }
}

.customSlider ul {
    display: none !important;
}

@media screen and (max-width: 520px) {
    .slickPrevBtn {
        left: -6px;
        img {
            height: 2rem;
            width: 2rem;
        }
    }
    .slickNextBtn {
        right: -6px;
        img {
            height: 2rem;
            width: 2rem;
        }
    }
    .sliderRef{
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

