.opportunity_card {
  width: 300px;
  height: 350px;
  background-color: #fff;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 0px;
  border: 1px solid #2ea1e82b;
  box-shadow: 5px 5px 0px 2px #2ea1e82b;
  margin: 10px;
}
.opportunity_card:hover {
  transition: all 0.3s ease-in-out;
  transform: scale(101%);
  box-shadow: 10px 10px 0px 2px #2ea1e879;
}
.opportunity_card .opportunity_img {
  width: 150px;
  height: 150px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2ea1e818;
  border-radius: 100%;
}
.opportunity_card .opportunity_img img {
  width: 100px;
}
.opportunity_card .oppor_heading {
  font-size: 18px;
  font-weight: 500;
  margin: 5px 0px;
}
.opportunity_card ul {
  margin: 0;
  padding: 0;
}
.opportunity_card ul li {
  list-style: none;
  font-size: 12px;
  color: gray;
}
